<template>
  <div class="category-navigator VOD-row-card" v-if="category.contents && category.contents.length > 0">
    <div class="category_related">
      <h3>{{ category.categoryName }}</h3>
      <div class="categories_details">
        <button :class="{ isVisible: showleftScroll }" class="desktop-only-content" @click="leftScrollCardContent">
          &lt;
        </button>
        <div class="category_programs" ref="cards">
          <button
            v-for="(program, idx) in category.contents"
            :key="idx"
            class="category-program-card-vod"
            :style="containerStyle"
            @click="openHalfPlayer(program)"
            @focusin="getFocusDetails(program)"
            @focusout="getFocusout(program)"
          >
            <div
              v-bind:class="getTypeOfCard(program)"
              :style="{
                backgroundImage: `url(${program.posterImg ||
                  program.thumbnailUrl ||
                  program.thumbnailImg ||
                  program.previewImgUrl})`,
              }"
              :tabindex="getTabIndex(idx)"
              @click="openHalfPlayer(program)"
            ></div>
            <p class="hover-only-content text-trim-ellipsis">{{ program.title }}</p>
            <div class="favorite-icon-row">
              <i :class="[isFavoriteContent(program) ? 'fa fa-star whitish' : '']"></i>
            </div>
            <div class="progress-bar">
              <div class="progress" :style="`width: ${program.watchedPercentage || 0}%`"></div>
            </div>
          </button>
        </div>
        <button :class="{ isVisible: showRightScroll }" class="desktop-only-content" @click="rightScrollCardContent">
          &gt;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '../event-bus';

export default {
  name: 'SuggestedVideo',
  props: {
    category: Object,
    isSuggestedVideo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    containerStyle() {
      const translateX = -this.currentIndex * 100 + `%`;
      return { transform: `translateX(${translateX})`, visibility: `visible` };
    },
  },
  data() {
    return {
      scrollOffset: 0,
      scrollSpeed: 100,
      scrollInterval: null,
      currentIndex: 0,
      showleftScroll: false,
      showRightScroll: false,

      scrollPosition: 0,
      maxScrollPosition: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.checkIsScrollenableForRow();
    }, 500);
  },
  methods: {
    checkIsScrollenableForRow() {
      this.isleftScroll();
      this.isRightScroll();
    },
    isleftScroll() {
      const container = this.$refs.cards;
      this.showleftScroll = container && container.scrollWidth > container.offsetWidth && container.scrollLeft > 0;
    },
    isRightScroll() {
      const container = this.$refs.cards;
      this.showRightScroll = container && container.scrollWidth > container.offsetWidth + container.scrollLeft;
    },
    leftScrollCardContent() {
      const container = this.$refs.cards;
      container.scrollLeft -= document.querySelector('.category_programs').clientWidth;
      this.checkIsScrollenableForRow();
    },

    rightScrollCardContent() {
      const container = this.$refs.cards;
      container.scrollLeft += document.querySelector('.category_programs').clientWidth;
      this.checkIsScrollenableForRow();
    },

    openHalfPlayer(program) {
      this.$emit('addToRecentlyWatched', program);
      eventBus.$emit('disposePlayer');
      if (this.isSuggestedVideo) {
        this.$emit('closeHalfPlayer');
      }
      this.$emit('openHalfPlayer', program);
    },
    getTypeOfCard(program) {
      if (program.posterImg) {
        return 'content_link_portrait';
      }
      return 'content_link_landscape';
    },
    startLeftAutoScroll() {
      if (!this.scrollInterval) {
        this.scrollInterval = setInterval(this.leftScrollContent, 15);
      }
    },
    leftScrollContent() {
      const container = this.$refs.cards;
      container.scrollLeft -= this.scrollSpeed;
    },
    startAutoScroll(data) {
      const offsetSetWidthData = this.$refs.cards;
      const totalWidth = offsetSetWidthData.offsetWidth;
      if (data.clientX <= totalWidth / 10) {
        if (!this.scrollInterval) {
          this.scrollInterval = setInterval(this.leftScrollContent, 15);
        }
      } else if (data.clientX >= totalWidth * 0.9) {
        if (!this.scrollInterval) {
          this.scrollInterval = setInterval(this.scrollContent, 15);
        }
      }
    },
    stopAutoScroll() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        this.scrollInterval = null;
      }
    },
    scrollContent() {
      const container = this.$refs.cards;
      container.scrollLeft += this.scrollSpeed;
    },
    getTabIndex(index) {
      return index + 1;
    },
    isFavoriteContent(program) {
      let favoriteVOD = JSON.parse(localStorage.getItem('favorite-vod')) || [];
      const existingIndex = favoriteVOD.findIndex(item => item.contentId === program.contentId);
      return existingIndex > -1 ? true : false;
    },
    getFocusDetails(program) {
      console.log('getFocusDetails ', program);
    },
    getFocusout(program) {
      console.log('getFocusout ', program);
    },
  },
};
</script>
