<template>
  <div v-if="showPlayerPopup" class="vodPlayer-details-popup">
    <div class="video-popup-content">
      <div class="new-content-border">
        <div class="video_wrap" style="border: 2px solid white; border-radius: 6px">
          <video-player
            id="ondemandPlayer"
            class="vjs-big-play-centered"
            ref="video_container"
            :player="player"
            :channelInfo="videoData"
            :programInfo="videoData"
            @closeHalfPlayer="closeHalfPlayer"
          ></video-player>
        </div>
        <div :style="{ marginTop: '2%' }">
          <ProgramInfo
            :style="{ display: programInfoDisplay }"
            :channelInfo="videoData"
            :programInfo="videoData"
            :programInfoDisplay="programInfoDisplay"
            @focustab="focusToTab"
            @addToFavoriteVOD="addToFavoriteVOD"
          ></ProgramInfo>
        </div>

        <!-- Related Videos -->
        <div :style="{ marginTop: '2%' }">
          <row-card
            :category="relatedVideos"
            :isSuggestedVideo="isSuggestedVideo"
            @suggestedVideoClicked="suggestedVideoClicked"
            @openHalfPlayer="openHalfPlayer"
            @closeHalfPlayer="closeHalfPlayer"
            v-if="isMovieData(videoData.contentType || videoData.type || videoData)"
          />
          <SeasonDetails
            v-if="isSeriesData(videoData.contentType || videoData.type)"
            :category="seasonData"
            :isSuggestedVideo="isSuggestedVideo"
            @updateSeasonVod="updateSeasonVod"
            @openHalfPlayer="openHalfPlayer"
            @closeHalfPlayer="closeHalfPlayer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramInfo from './programInfo.vue';
import RowCard from './rowCard.vue';
import SeasonDetails from './seasonDetails.vue';
import VideoPlayer from './VODVideoPlayer.vue';

export default {
  name: 'VODPlayerPopUp',
  props: {
    showPlayerPopup: {
      type: Boolean,
      required: true,
    },
    videoData: {
      type: Object,
      required: true,
      immediate: true,
    },
    relatedVideos: {
      type: Object,
      required: true,
    },
    seasonData: {
      type: Object,
      required: false,
      immediate: true,
    },
  },
  watch: {
    videoData: {
      deep: true,
      immediate: true,
      handler(newVal) {
        let src = {
          src: newVal.mediaUrl,
          type: 'application/x-mpegURL',
        };
        this.$store.state.playSrc = src;
      },
    },
  },
  data() {
    return {
      player: { player: null, textSettingHegiht: '' },
      programInfoDisplay: 'block',
      isSuggestedVideo: true,
    };
  },
  components: {
    VideoPlayer,
    RowCard,
    SeasonDetails,
    ProgramInfo,
  },
  methods: {
    closePopup() {
      this.$emit('closeHalfPlayer');
    },
    focusToTab() {
      this.$emit('focusToTab');
    },
    isMovieData(contentType) {
      return contentType.toUpperCase() === 'MOVIE';
    },
    isSeriesData(contentType) {
      return contentType.toUpperCase() === 'TVSHOW' || contentType.toUpperCase() === 'TVSHOW-SERIES';
    },
    suggestedVideoClicked() {
      this.$emit('suggestedVideoClicked');
    },
    openHalfPlayer(program) {
      this.$emit('openHalfPlayer', program);
    },
    closeHalfPlayer(programInfo, percentage, watchedTime) {
      this.$emit('closeHalfPlayer', programInfo, percentage, watchedTime);
    },
    updateSeasonVod(season) {
      this.$emit('updateSeasonVod', season);
    },
    addToFavoriteVOD(program) {
      this.$emit('addToFavoriteVOD', program);
    },
  },
};
</script>
<style lang="scss">
.vodPlayer-details-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  z-index: 999;
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: black;
  word-break: break-word;
  .status_container {
    .ellipsis {
      font-size: 1.3rem;
      font-weight: 400;
      white-space: normal;
    }
    p {
      color: grey;
    }
  }
  .progress_inner .programDetails {
    margin-bottom: 2vw;
    padding-left: 0;
    .ch_desc {
      display: none;
    }
  }
  .videoPlayerMain-dimensions.vjs-fluid {
    padding-top: 56.25%;
  }
  .txt_wrap {
    width: 100%;
  }
}

.vodPlayer-details-popup .video-popup-content {
  height: 100%;
  width: 80%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 1%;
}

.vodPlayer-details-popup::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  display: none;
}

.new-content-border {
  padding: 2.5%;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 1);
}

@media screen and (max-width: 1000px) {
  .vodPlayer-details-popup {
    z-index: 0;
    .new-content-border {
      padding: 0 7px;
      border-radius: 0;
    }
    .ch_desc,
    p {
      font-size: 0.8rem;
    }
  }

  .vodPlayer-details-popup .video-popup-content {
    width: 100%;
    padding: 0;
    margin-top: 130px !important;
  }
  .vodPlayer-details-popup .status_container .ellipsis {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1240px) {
  .vodPlayer-details-popup {
    .video-popup-content {
      margin-top: 9vw;
    }
    .progress_inner .programDetails {
      margin-bottom: 2vw;
    }
  }
}
</style>
