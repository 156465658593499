<template>
  <div class="category-navigator VOD-row-card">
    <div class="category_related" v-if="category.contents && category.contents.length > 0">
      <h3>{{ category.categoryName }}</h3>
      <div class="categories_details">
        <button :class="{ isVisible: showleftScroll }" class="desktop-only-content" @click="leftScrollCardContent">
          &lt;
        </button>
        <div class="category_programs" ref="cards">
          <button
            v-for="(program, idx) in category.contents"
            :key="idx"
            class="category-program-card-vod"
            :style="containerStyle"
            @click="openHalfPlayer(program)"
            @focusin="getFocusDetails(program)"
            @focusout="getFocusout(program)"
          >
            <div
              v-bind:class="getTypeOfCard(program)"
              :style="{
                backgroundImage: `url(${program.posterImg ||
                  program.thumbnailUrl ||
                  program.thumbnailImg ||
                  program.previewImgUrl})`,
              }"
              :tabindex="getTabIndex(idx)"
              @click="openHalfPlayer(program)"
            ></div>
            <div class="now_tag" v-if="program.newFlag === 'Y'">{{ getTextdata('new-tag') }}</div>
            <p class="hover-only-content text-trim-ellipsis">{{ program.title }}</p>
            <div class="favorite-icon-row">
              <i :class="[isFavoriteContent(program) ? 'fa fa-star whitish' : '']"></i>
            </div>
            <div class="progress-bar" v-if="program.watchedPercentage">
              <div class="progress" :style="`width: ${program.watchedPercentage}%`"></div>
            </div>
          </button>
        </div>
        <button :class="{ isVisible: showRightScroll }" class="desktop-only-content" @click="rightScrollCardContent">
          &gt;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '../event-bus';

export default {
  name: 'RowCards',
  props: {
    category: Object,
    isSuggestedVideo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    containerStyle() {
      const translateX = -this.currentIndex * 100 + `%`;
      return { transform: `translateX(${translateX})` };
    },
  },
  data() {
    return {
      scrollOffset: 0,
      scrollSpeed: 100,
      scrollInterval: null,
      currentIndex: 0,
      showleftScroll: false,
      showRightScroll: false,

      scrollPosition: 0,
      maxScrollPosition: 0,
      watchedPercentage: 50,
    };
  },
  mounted() {
    this.checkIsScrollenableForRow();
  },
  methods: {
    checkIsScrollenableForRow() {
      this.isleftScroll();
      this.isRightScroll();
    },
    isleftScroll() {
      const container = this.$refs.cards;
      this.showleftScroll = container && container.scrollWidth > container.offsetWidth && container.scrollLeft > 0;
    },
    isRightScroll() {
      const container = this.$refs.cards;
      this.showRightScroll = container && container.scrollWidth > container.offsetWidth + container.scrollLeft;
    },
    leftScrollCardContent() {
      const container = this.$refs.cards;
      container.scrollLeft -= document.querySelector('.category_programs').clientWidth;
      this.checkIsScrollenableForRow();
    },

    rightScrollCardContent() {
      const container = this.$refs.cards;
      container.scrollLeft += document.querySelector('.category_programs').clientWidth;
      this.checkIsScrollenableForRow();
    },

    openHalfPlayer(program) {
      eventBus.$emit('disposePlayer');
      this.$emit('addToRecentlyWatched', program);
      if (this.isSuggestedVideo) {
        this.$emit('closeHalfPlayer');
      }
      this.$emit('openHalfPlayer', program);
    },
    getTypeOfCard(program) {
      if (program.posterImg) {
        return 'content_link_portrait';
      }
      return 'content_link_landscape';
    },
    startLeftAutoScroll() {
      if (!this.scrollInterval) {
        this.scrollInterval = setInterval(this.leftScrollContent, 15);
      }
    },
    leftScrollContent() {
      const container = this.$refs.cards;
      container.scrollLeft -= this.scrollSpeed;
    },
    startAutoScroll(data) {
      const offsetSetWidthData = this.$refs.cards;
      const totalWidth = offsetSetWidthData.offsetWidth;
      if (data.clientX <= totalWidth / 10) {
        if (!this.scrollInterval) {
          this.scrollInterval = setInterval(this.leftScrollContent, 15);
        }
      } else if (data.clientX >= totalWidth * 0.9) {
        if (!this.scrollInterval) {
          this.scrollInterval = setInterval(this.scrollContent, 15);
        }
      }
    },
    stopAutoScroll() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        this.scrollInterval = null;
      }
    },
    scrollContent() {
      const container = this.$refs.cards;
      container.scrollLeft += this.scrollSpeed;
    },
    getTabIndex(index) {
      return index + 1;
    },
    toggleFavorite(program) {
      this.$emit('addToFavoriteVOD', program);
    },
    isFavoriteContent(program) {
      let favoriteVOD = JSON.parse(localStorage.getItem('favorite-vod')) || [];
      const existingIndex = favoriteVOD.findIndex(item => item.contentId === program.contentId);
      return existingIndex > -1 ? true : false;
    },
  },
};
</script>

<style lang="scss">
.category_related {
  flex: 1;
  width: 100%;
  margin-bottom: 10px;
}

.category_related h3 {
  margin-left: 30px;
  font-weight: 400;
}

.categories_details {
  display: flex;
}

.category_programs {
  display: flex;
}

.category_programs::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  display: none;
}

.category-program-card-vod {
  flex: 0 0 200px;
  margin: 5px 20px 5px 0;
  padding: 0;
}

.content_link_landscape,
.content_link_portrait {
  width: 27rem;
  height: 15rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
}

.content_link_portrait {
  width: 10rem;
  height: 15rem;
}

.continue-watching-container .category-navigator:first-child .category-program-card-vod .progress-bar {
  display: block;
}

.hover-only-content {
  visibility: hidden;
  color: grey;
}

.category-program-card-vod:hover .hover-only-content {
  visibility: visible;
}

.progress-bar {
  background-color: #fff;
  height: 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%);
  display: none;
  width: 95%;
}

.progress {
  background-color: red;
  height: 100%;
  border-radius: 5px;
}

.category-navigator {
  display: flex;
  align-items: center;
}

button {
  margin: 0 10px;
  padding: 5px 10px;
}

.category-container {
  overflow: hidden;
  width: 100%;
}

.categories {
  display: flex;
  transition: transform 0.3s;
}

.category {
  flex: 0 0 100%;
  text-align: center;
  padding: 20px;
}

.category-navigator .desktop-only-content {
  visibility: hidden;
  margin: 0;
}

.isVisible {
  visibility: visible !important;
}

.VOD-row-card {
  button:focus {
    visibility: visible !important;
    .hover-only-content {
      visibility: visible;
    }
  }
}

@media screen and (max-width: 480px) {
  .category_related h3 {
    font-size: 20px;
  }
  .category_related {
    margin-bottom: 0;
  }

  .category-program-card-vod {
    margin: 15px 15px 5px 0;
    flex: auto;
  }

  .category-program-card-vod p {
    font-size: 14px;
  }

  .content_link_portrait {
    width: 6rem;
    height: 9rem;
  }

  .content_link_landscape {
    width: 12rem;
    height: 9rem;
  }

  .category-navigator {
    margin: 0 10px;
  }

  .category_related h3 {
    margin-left: 0;
  }
}

@media screen and (max-width: 1020px) {
  .hover-only-content {
    visibility: visible;
  }
  .progress-bar {
    bottom: 52px;
  }
}
</style>
