<template>
  <div class="category_related season-details">
    <div class="scrollable-containers">
      <div v-for="(Season, idx) in category.seasonInfo" :key="idx">
        <button
          class="season-btn"
          :class="{ active: Season.episodeInfo && Season.episodeInfo.length > 0 }"
          @click="updateSeasonInfo(Season)"
        >
          Season {{ Season.seasonNumber }}
        </button>
      </div>
    </div>
    <div v-for="(seasonInfo, idx) in category.seasonInfo" :key="idx">
      <div v-if="seasonInfo.episodeInfo && seasonInfo.episodeInfo.length > 0">
        <div v-for="(episode, idx) in seasonInfo.episodeInfo" :key="idx">
          <div class="series-style" @click="changeEpisodeCategory(episode)">
            <div class="series-thumbnail" :style="{ backgroundImage: `url(${episode.previewImgUrl})` }"></div>
            <div class="series-content">
              <div class="series-number text-trim-ellipsis">{{ 'E' + episode.episodeNumber }} {{ episode.title }}</div>
              <div class="series-desc text-trim-ellipsis">{{ episode.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeasonDetails',
  props: {
    category: Object,
    isSuggestedVideo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      scrollOffset: 0,
      scrollSpeed: 100,
      scrollInterval: null,
      scrollPosition: 0,
    };
  },
  computed: {
    containerStyle() {
      return {
        transform: `translateX(${this.scrollPosition}px)`,
      };
    },
  },
  // watch: {
  //   category: {
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  methods: {
    openHalfPlayer(program) {
      if (this.isSuggestedVideo) {
        this.$emit('closePlayerPopup');
      }
      this.$emit('openHalfPlayer', program);
    },
    getTypeOfCard(contentType) {
      if (contentType === 'MOVIE' || contentType === 'TVSHOW') {
        return 'content_link_portrait';
      }
      return 'content_link_landscape';
    },
    startLeftAutoScroll() {
      if (!this.scrollInterval) {
        this.scrollInterval = setInterval(this.leftScrollContent, 15);
      }
    },
    leftScrollContent() {
      const container = this.$refs.cards;
      container.scrollLeft -= this.scrollSpeed;
    },
    startAutoScroll(data) {
      const offsetSetWidthData = this.$refs.cards;
      const totalWidth = offsetSetWidthData.offsetWidth;
      if (data.clientX <= totalWidth / 10) {
        if (!this.scrollInterval) {
          this.scrollInterval = setInterval(this.leftScrollContent, 15);
        }
      } else if (data.clientX >= totalWidth * 0.9) {
        if (!this.scrollInterval) {
          this.scrollInterval = setInterval(this.scrollContent, 15);
        }
      }
    },
    stopAutoScroll() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
        this.scrollInterval = null;
      }
    },
    scrollContent() {
      const container = this.$refs.cards;
      container.scrollLeft += this.scrollSpeed;
    },
    getTabIndex(index) {
      return index + 1;
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      const touchX = event.touches[0].clientX;
      const deltaX = this.touchStartX - touchX;
      this.scrollPosition += deltaX;
      this.touchStartX = touchX;
    },
    changeEpisodeCategory(episode) {
      this.$emit('openHalfPlayer', episode);
    },
    async updateSeasonInfo(season) {
      this.$emit('updateSeasonVod', season);
    },
  },
};
</script>

<style lang="scss">
.season-details {
  .scrollable-containers::-webkit-scrollbar {
    -webkit-overflow-scrolling: touch;
    display: none;
  }

  .scrollable-containers {
    display: flex;
    overflow-x: scroll;
  }

  .season-btn {
    padding: 10px 30px;
  }

  button {
    color: white;
    border-radius: 10px;
    border: 1px solid white;
  }

  .series-style {
    display: flex;
    margin-top: 25px;
  }

  .series-thumbnail {
    height: 100px;
    width: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
  }

  .series-content {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    div {
      font-size: 16px;
      font-weight: bold;
    }
    .series-title {
      font-size: 20px;
    }
    .series-desc {
      font-weight: normal;
    }
  }

  button.active {
    background-color: #fff;
    color: black;
  }

  @media screen and (max-width: 480px) {
    .season-btn {
      font-size: 16px;
      padding: 10px 15px;
      width: 95px;
    }

    .series-content {
      margin-left: 0.5rem;
      flex-basis: 60% div {
        font-size: 14px;
      }
      .series-title {
        font-size: 18px;
      }
    }

    .series-thumbnail {
      height: 90px;
      width: 160px;
    }
  }
}
</style>
