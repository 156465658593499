<template>
  <div>
    <continue-watching
      :continueWatchItems="continueWatchItems"
      :favoriteItems="favoriteItems"
      @openHalfPlayer="openHalfPlayer"
      @closeHalfPlayer="closeHalfPlayer"
      @addToRecentlyWatched="addToRecentlyWatched"
      @addToFavoriteVOD="addToFavoriteVOD"
    ></continue-watching>
    <div v-for="(category, index) in channelList" :key="index" class="category_related">
      <RowCards
        key="index"
        :category="category"
        @openHalfPlayer="openHalfPlayer"
        @addToRecentlyWatched="addToRecentlyWatched"
        @addToFavoriteVOD="addToFavoriteVOD"
      />
    </div>
  </div>
</template>

<script>
import ContinueWatching from './continueWatching.vue';
import RowCards from './rowCard.vue';
export default {
  name: 'Categories',
  components: {
    RowCards,
    ContinueWatching,
  },
  props: {
    channelList: {
      type: Array,
      required: true,
    },
    continueWatchItems: {
      type: Object,
      required: true,
      immediate: true,
    },
    favoriteItems: {
      type: Object,
      required: true,
      immediate: true,
    },
  },
  methods: {
    openHalfPlayer(program) {
      this.$emit('openHalfPlayer', program);
    },
    closeHalfPlayer(program) {
      this.$emit('closeHalfPlayer', program);
    },
    addToRecentlyWatched(program) {
      this.$emit('addToRecentlyWatched', program);
    },
    addToFavoriteVOD(program) {
      this.$emit('addToFavoriteVOD', program);
    },
  },
};
</script>

<style>
.category_related {
  flex: 1;
  width: 100%;
}

.category_programs {
  display: flex;
  overflow-x: scroll;
}
</style>
