<template>
  <div
    :class="{
      tab_active: tabNavigation,
      ios_video_js: iosDevice,
      full_landscape: isFullscreenPortrait,
    }"
    class="video_container VOD_container"
    :style="{
      '--data-content4': iosSetting[0].value,
      '--data-content5': iosSetting[1].value,
      '--data-content6': iosSetting[2].value,
      '--data-content7': iosSetting[3].value,
      '--data-content8': iosSetting[4].value,
      height: '100%',
    }"
  >
    <video
      ref="videoPlayer"
      id="videoPlayerMain"
      playsinline
      class="video-js vjs-default-skin vjs-show-big-play-button-on-pause vjs-text-track"
      :class="{
        'video-mobile': isMobile,
      }"
      style="margin: auto 0"
      :style="{
        '--data-content2': `'${getTextdata('Captions')}'`,
        backgroundColor: errorCode ? '#333' : '',
      }"
      @keyup.space="keyUpSapce()"
    ></video>
    <div class="custom-left-controls" @click="closeHalfPlayer">
      <div class="video-cancel-icon">
        <span><i class="fas fa-angle-left"></i> {{ ' ' }}</span>
        <span v-if="isFullScreen">{{ programInfo.title }}</span>
      </div>
    </div>
    <select v-model="selectedQuality">
      <option v-for="quality in availableQualities" :key="quality.id" :value="quality.id">{{ quality.label }}</option>
    </select>
    <div class="progress_inner" id="progress-line">
      <div class="programDetails desktop-only-content">
        <span class="ch_program_title" v-if="programInfo.title">{{ programInfo.title }}</span>
        <br />
        <span class="ch_desc" v-if="programInfo.description">{{ programInfo.description }}</span>
      </div>
    </div>
    <div class="progess-controls video_wrap">
      <div class="top-controls">
        <div class="watched-time">{{ formatTime(currentTime) }}</div>
        <div class="vod-progress-bar">
          <input
            type="range"
            class="vod-progress"
            min="0"
            max="100"
            step="0.01"
            v-model="progressPercentage"
            @input="seekTo"
          />
        </div>
        <div class="remaining-time">{{ formatTime(duration - currentTime) }}</div>
        <div class="fullscreen-control">
          <button @click="toggleFullscreen">
            <i v-show="!isFullScreen" class="fas fa-expand"></i>
            <i v-show="isFullScreen" class="fas fa-compress"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import videojs from '../plugins/video.js/dist/video.js';
import videojsContribEme from 'videojs-contrib-eme';
import videoMixin from '../mixins/video';
import { mapGetters } from 'vuex';

export default {
  name: 'VODVideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    player: Object,
    channelInfo: Object,
    programInfo: Object,
  },
  mixins: [videoMixin],
  inject: ['beacon'],
  watch: {
    channelInfo: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal.channelId !== oldVal?.channelId) {
          this.count = 0;
        }
      },
    },
    programInfo: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal.programId !== oldVal?.programId) {
          if (this.isPlaying() && this.channelInfo) {
            this.beacon.updateLastContentDuration();
            this.beacon.saveContent(newVal, this.channelInfo);
            // Change quality when selectedQuality changes
            this.$watch('selectedQuality', newQuality => {
              const selectedQuality = this.player.player.qualityLevels().find({ id: newQuality });
              if (selectedQuality) {
                selectedQuality.enabled = true;
              }
            });
          }
        }
      },
    },
    settingData: {
      deep: true,
      handler() {
        if (this.screenMode === 'portrait') {
          if (this.settingData.open) {
            document.documentElement.style.setProperty('overflow', 'hidden', 'important');
          } else {
            document.documentElement.style.setProperty('overflow', 'auto', 'important');
          }
        }
      },
    },
    videoHeight: {
      handler(newVal) {
        setTimeout(() => {
          this.player.player.el_.style.setProperty(
            'height',
            newVal && parseFloat(newVal) < this.player?.player?.el_?.offsetHeight ? newVal : 'auto'
          );
        }, 100);
      },
    },
    errorCode: {
      handler(newVal) {
        let message = '';
        let title = '';
        if (typeof newVal === 'number' && newVal >= 0 && newVal <= 5) {
          title = this.$t('components.errorPopup.mediaErrorTitle');
          message =
            this.$t('components.errorPopup.errorGuide') +
            '\\A\\A' +
            this.$t('components.errorPopup.errorCode', { number: `70${newVal}` });
        }
        this.player.player?.el_?.style.setProperty('--data-content', `"${title}"`);
        this.player.player?.el_?.style.setProperty('--data-content3', `"${message}"`);
      },
    },
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
      screenMode: 'getScreenMode',
      videoHeight: 'getVideoHeight',
    }),
    getTextdata() {
      return whattext => {
        return this.$t(`components.VideoPlayer.${whattext}`) ?? '';
      };
    },
    getVideoHeight() {
      if (this.videoHeight && this.player?.player?.el_?.offsetHeight) {
        return parseFloat(this.videoHeight) < this.player.player.el_.offsetHeight
          ? this.videoHeight
          : this.player.player.el_.offsetHeight;
      }
      return '';
    },
    getTextTrackStatus() {
      let trackListObj = this.player.player.remoteTextTracks();
      if (this.iosDevice) {
        trackListObj = this.player.player.textTracks();
      }
      let notHidden = 0;
      let activeText = 0;
      for (let track of trackListObj.tracks_) {
        if (track.mode !== 'hidden') {
          notHidden++;
        }
        if (this.iosDevice && trackListObj.tracks_[0].language === '') {
          notHidden = track.language ? 1 : 0;
        }
        if (track.label.indexOf('cc') > -1) {
          this.player.player.removeRemoteTextTrack(track);
        }
        if (track.language) {
          activeText++;
        }
      }
      return {
        notHidden: notHidden,
        activeText: activeText,
      };
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    isFullscreenPortrait() {
      if (!this.player?.player) return false;
      else if (!this.isMobile || this.iosDevice || this.iosiPad) return false;
      return this.player.player.isFullscreen() && this.screenMode === 'portrait';
    },
    isFullScreen() {
      if (!this.player?.player) return false;
      return this.player.player.isFullscreen();
    },
  },
  data() {
    return {
      time: '',
      volumeToggleTime: '',
      currentTime: 0,
      settingData: { open: false },
      count: 0,
      errorCode: null,
      errorMsg: '',
      availableQualities: [],
      selectedQuality: null,

      step: 0,
      settingItems: [
        {
          title: 'Character-Color',
          value: 'White',
          valueIndex: 0,
          items: ['White', 'Black', 'Blue', 'Cyan', 'Green', 'Magenta', 'Red', 'Yellow'],
          itemValues: ['#FFF', '#000', '#00F', '#0FF', '#0F0', '#F0F', '#F00', '#FF0'],
        },
        {
          title: 'Character-opacity',
          value: '100%',
          valueIndex: 0,
          items: ['100%', '50%'],
          itemValues: ['1', '0.5'],
        },
        {
          title: 'Character-Size',
          value: '100%',
          valueIndex: 2,
          items: ['50%', '75%', '100%', '125%', '150%', '175%', '200%', '300%', '400%'],
          itemValues: [0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 3.0, 4.0],
        },
        {
          title: 'Fonts',
          value: 'Proportional Sans-Serif',
          valueIndex: 0,
          items: [
            'Proportional Sans-Serif',
            'Monospace Sans-Serif',
            'Proportional Serif',
            'Monospace Serif',
            'Casual',
            'Script',
            'Small Caps',
          ],
          itemValues: [
            'proportionalSansSerif',
            'monospaceSansSerif',
            'proportionalSerif',
            'monospaceSerif',
            'casual',
            'script',
            'small-caps',
          ],
        },
        {
          title: 'Caption-background-color',
          value: 'Black',
          valueIndex: 0,
          items: ['Black', 'Blue', 'Cyan', 'Green', 'Magenta', 'Red', 'White', 'Yellow'],
          itemValues: ['#000', '#00F', '#0FF', '#0F0', '#F0F', '#F00', '#FFF', '#FF0'],
        },
        {
          title: 'Caption-background-opacity',
          value: '100%',
          valueIndex: 0,
          items: ['100%', '50%', '0%'],
          itemValues: ['1', '0.5', '0'],
        },

        {
          title: 'Caption-window-color',
          value: 'Black',
          valueIndex: 0,
          items: ['Black', 'Blue', 'Cyan', 'Green', 'Magenta', 'Red', 'White', 'Yellow'],
          itemValues: ['#000', '#00F', '#0FF', '#0F0', '#F0F', '#F00', '#FFF', '#FF0'],
        },
        {
          title: 'Caption-window-opacity',
          value: '0%',
          valueIndex: 2,
          items: ['100%', '50%', '0%'],
          itemValues: ['1', '0.5', '0'],
        },
      ],
      secondItem: 0,
      tabNavigation: false,
      iosDevice: false,
      iosiPad: false,
      touchScrolled: false,
      iosSetting: [
        // text color:
        { value: '#FFF' },
        // text opacity
        { value: 1 },
        // font
        { value: 'sans-serif' },
        // Character-Size
        { value: '1em' },
        // Caption-window-color
        { value: '' },
      ],
      duration: 0,
      progressPercentage: 0,
    };
  },
  created() {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      this.iosDevice = true;
    }
    // Macintosh? max touch point ? ?? ??? ios ??
    else if (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 0) {
      this.iosDevice = true;
      this.iosiPad = true;
    } else if (navigator.platform.toUpperCase().indexOf('MAC') >= 0) {
      // mac - safari ? ipad? ?? ??
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        this.iosDevice = true;
        this.iosiPad = true;
      }
    }
  },
  mounted() {
    // require('@silvermine/videojs-quality-selector')(videojs);
    // require('@silvermine/videojs-quality-selector/dist/css/quality-selector.css');

    this.setLanguageData('en');

    this.setPlayerOptions('videoPlayerMain', 'en');

    // this.player.player.controlBar.progressControl.disable();

    this.setPlayerEventHandler();

    this.player.player.bigPlayButton.show();

    this.setControlUIEventHandler();

    this.setGlobalInputEventHandler();
  },
  beforeDestroy() {
    if (this.player.player) {
      this.player.player.dispose();
    }
  },

  methods: {
    getPlaySrc() {
      let playSrc = Object.assign({}, this.$store.state.playSrc);
      playSrc.src = this.getUrlWithSSAIParameters(playSrc.src);
      return playSrc;
    },
    pauseVideo() {
      this.player.player.pause();
      this.clearTracks();
    },
    closeHalfPlayer() {
      this.$emit(
        'closeHalfPlayer',
        this.programInfo,
        this.progressPercentage,
        (this.progressPercentage / 100) * this.duration
      );
    },
    checkHlsSupport(src) {
      const video = this.player.player;

      // Check if HLS is supported
      if (
        video.canPlayType('application/vnd.apple.mpegurl') === 'probably' ||
        video.canPlayType('application/vnd.apple.mpegurl') === 'maybe'
      ) {
        // HLS is likely supported
        return true;
      }

      // Check the provided source URL
      video.src = src;
      return !!(
        video.canPlayType('application/vnd.apple.mpegurl') === 'probably' ||
        video.canPlayType('application/vnd.apple.mpegurl') === 'maybe'
      );
    },
    getHLS() {
      // Enable videojs-contrib-hls for adaptive streaming
      // this.player.player.hls();
      // Add quality control options (if available in the HLS stream)
      // this.player.player.qualityLevels();
      // // Customize quality menu labels
      // this.player.player.qualityLevels().on('addqualitylevel', (e, qualityLevel) => {
      //   qualityLevel.label = `${qualityLevel.height}p`;
      // });
    },
    clearTracks() {
      let list = this.player.player.remoteTextTrackEls() || [];
      let i = list.length;

      while (i--) {
        let track = list[i];
        this.player.player.removeRemoteTextTrack(track);
      }
    },
    retryPlaying() {
      if (this.count < 3) {
        document.getElementsByClassName('vjs-error-display')[0].style.display = 'none';
        this.count++;
        if (!this.isPlaying()) {
          this.player.player.src(this.getPlaySrc());
          const targetTime = (this.progressPercentage / 100) * this.duration;
          this.player.player.currentTime(targetTime);
          this.player.player.play();
        }
      } else {
        document.getElementsByClassName('vjs-error-display')[0].style.display = '';
      }
    },
    keyUpSapce() {
      if (this.isPlaying()) {
        this.pauseVideo();
      } else {
        this.player.player.src(this.getPlaySrc());
        const targetTime = (this.progressPercentage / 100) * this.duration;
        this.player.player.currentTime(targetTime);
        this.player.player.play();
        // this.getHLS();
      }
    },
    settingTabbed(event) {
      event.preventDefault();
      event.stopPropagation();
      let trackListObj = this.player.player.remoteTextTracks();
      let activeText = 0;
      for (let track of trackListObj.tracks_) {
        if (track.language) {
          activeText++;
        }
      }
      if (activeText > 0) {
        let captionMenus = document.querySelector('.vjs-menu-item.vjs-texttrack-settings');
        captionMenus.parentElement.children[1].focus();
      }
    },
    settingEnterred() {
      setTimeout(() => {
        this.$refs.text_setting_first_prev.focus();
      }, 300);
    },
    prevButtonTabbed(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.step === 0) {
        this.$refs[`text_setting_first_item_${event.shiftKey ? this.settingItems.length - 1 : 0}`][0].focus();
      } else {
        this.$refs[
          `text_setting_second_item_${event.shiftKey ? this.settingItems[this.secondItem].items.length - 1 : 0}`
        ][0].focus();
      }
    },
    prevButtonEnterred() {
      // space, enter key
      if (this.step === 0) {
        setTimeout(() => {
          this.$refs.captions_setting.focus();
        }, 300);
      }
    },
    textSettingItemTabbed(event, i) {
      event.preventDefault();
      event.stopPropagation();
      let whatsetting = this.step === 0 ? 'first' : 'second';
      if (!event.shiftKey) {
        if (
          (this.step === 0 && i === this.settingItems.length - 1) ||
          (this.step !== 0 && i === this.settingItems[this.secondItem].items.length - 1)
        ) {
          this.$refs[`text_setting_${whatsetting}_prev`].focus();
        } else this.$refs[`text_setting_${whatsetting}_item_${i + 1}`][0].focus();
      } else {
        (i === 0
          ? this.$refs[`text_setting_${whatsetting}_prev`]
          : this.$refs[`text_setting_${whatsetting}_item_${i - 1}`][0]
        ).focus();
      }
    },
    textSettingItemEnterred(event, i, secitem) {
      if (this.step === 0) {
        this.toSecond(event, i);
        setTimeout(() => {
          this.$refs.text_setting_second_prev.focus();
        }, 300);
      } else {
        this.secondSelect(event, secitem);
      }
    },

    isPlaying() {
      return document.getElementsByClassName('vjs-playing').length > 0;
    },
    isPaused() {
      return this.player.player.paused();
    },
    touchScroll() {
      this.touchScrolled = true;
    },
    openTextSetting(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$nextTick(() => {
        this.settingData.open = true;
        let captionsItem = Array.from(document.getElementsByClassName('vjs-captions-menu-item'));
        captionsItem.forEach(el => {
          el.style.display = 'none';
        });
      });
    },
    closeSetting(event) {
      if (this.iosDevice) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
      this.settingData.open = false;
      let captionsItem = Array.from(document.querySelectorAll('.vjs-captions-menu-item') ?? []);
      captionsItem.forEach(el => {
        el.style.display = 'flex';
      });
    },
    toSecond(event, index) {
      if (this.touchScrolled) {
        this.touchScrolled = false;
        return;
      }
      if (this.iosDevice) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.secondItem = index;
      this.step = 1;
    },
    secondSelect(event, secitem) {
      if (this.touchScrolled) {
        this.touchScrolled = false;
        return;
      }
      if (this.iosDevice) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.settingItems[this.secondItem].value = secitem;
      this.settingItems[this.secondItem].valueIndex = this.settingItems[this.secondItem].items.findIndex(
        e => e === secitem
      );

      let textSetting = {
        color: this.settingItems[0].itemValues[this.settingItems[0].valueIndex],
        textOpacity: this.settingItems[1].itemValues[this.settingItems[1].valueIndex],
        // fontPercent: this.settingItems[2].itemValues[this.settingItems[2].valueIndex],
        fontFamily: this.settingItems[3].itemValues[this.settingItems[3].valueIndex],
        backgroundColor: this.settingItems[4].itemValues[this.settingItems[4].valueIndex],
        backgroundOpacity: this.settingItems[5].itemValues[this.settingItems[5].valueIndex],
        windowColor: this.settingItems[6].itemValues[this.settingItems[6].valueIndex],
        windowOpacity: this.settingItems[7].itemValues[this.settingItems[7].valueIndex],
      };

      if (this.secondItem === 2) {
        textSetting.fontPercent = this.settingItems[2].itemValues[this.settingItems[2].valueIndex];
      }

      let ioScolor = textSetting.color;
      this.iosSetting[0].value = ioScolor;
      this.iosSetting[1].value = Number(textSetting.textOpacity);

      let fontMap = {
        monospace: 'monospace',
        sansSerif: 'sans-serif',
        serif: 'serif',
        monospaceSansSerif: '"Andale Mono", "Lucida Console", monospace',
        monospaceSerif: '"Courier New", monospace',
        proportionalSansSerif: 'sans-serif',
        proportionalSerif: 'serif',
        casual: '"Comic Sans MS", Impact, fantasy',
        script: '"Monotype Corsiva", cursive',
        'small-caps': '"Andale Mono", "Lucida Console", monospace, sans-serif',
      };
      this.iosSetting[2].value = fontMap[textSetting.fontFamily];
      this.iosSetting[3].value = `${1 * this.settingItems[2].itemValues[this.settingItems[2].valueIndex]}em`;

      let iosWindow = `rgb(${parseInt(textSetting.windowColor[1] + textSetting.windowColor[1], 16)}, ${parseInt(
        textSetting.windowColor[2] + textSetting.windowColor[2],
        16
      )}, ${parseInt(textSetting.windowColor[3] + textSetting.windowColor[3], 16)}, ${textSetting.windowOpacity})`;
      this.iosSetting[4].value = iosWindow;
      this.player.player.textTrackSettings.setValues(textSetting);
      // this.player.player.textTrackSettings.updateDisplay();
    },
    prevStep(event) {
      if (this.iosDevice) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.step = 0;
    },
    ccPopFocusOut(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$nextTick(() => {
        let captionMenu = Array.from(document.getElementsByClassName('vjs-menu vjs-lock-showing'));
        if (captionMenu[0]) {
          captionMenu[0].classList.remove('vjs-lock-showing');
          captionMenu[0].classList.add('vjs-hidden');
        }
        this.settingData.open = false;
        this.step = 0;

        let captionsItem = Array.from(document.getElementsByClassName('vjs-captions-menu-item'));
        captionsItem.forEach(el => {
          el.style.display = 'flex';
        });
      });
    },
    setLanguageData(langCode) {
      videojs.addLanguage(langCode, {
        Play: this.getTextdata('Play'),
        Pause: this.getTextdata('Pause'),
        'Current Time': this.getTextdata('Current-Time'),
        Duration: this.getTextdata('Duration'),
        'Remaining Time': this.getTextdata('Remaining-Time'),
        'Stream Type': this.getTextdata('Stream-Type'),
        LIVE: this.getTextdata('LIVE'),
        Loaded: this.getTextdata('Loaded'),
        Progress: this.getTextdata('Progress'),
        Fullscreen: this.getTextdata('Fullscreen'),
        'Non-Fullscreen': this.getTextdata('Non-Fullscreen'),
        Mute: this.getTextdata('Mute'),
        Unmute: this.getTextdata('Unmute'),
        'Playback Rate': this.getTextdata('Playback-Rate'),
        Subtitles: this.getTextdata('Captions'),
        'subtitles off': this.getTextdata('subtitles-off'),
        Captions: this.getTextdata('Captions'),
        'captions off': this.getTextdata('captions-off'),
        Chapters: this.getTextdata('Chapters'),
        'subtitles settings': this.getTextdata('subtitles-settings'),
        'captions settings': this.getTextdata('captions-settings'),
        'You aborted the media playback': this.getTextdata('aborted'),
        'Play Video': this.getTextdata('Play'),
      });
    },
    setPlayerOptions(playerId, langCode) {
      const videoOptions = {
        autoplay: false, // ?? ?? ??
        inactivityTimeout: 2000, // ??? mouse leave ? ?? ?? ??
        controls: true, // ??? ?? ??
        playbackRates: [0.5, 1, 1.5, 2],
        responsive: true,
        controlBar: {
          pictureInPictureToggle: false, // pip ?? ??
          remainingTimeDisplay: false, // ?? ?? ??
          currentTimeDisplay: false, // ?? ?? ??
          progressControl: false, // ?? ??? ? ??
        },
        language: langCode, // ?? ??
        fluid: true,
        playsinline: true,
      };
      this.player.player = videojs(playerId, videoOptions);
      if (this.player.player) {
        this.player.player.eme = videojsContribEme;
        this.player.player.poster(this.getPosterImage());
      }
      this.player.player.eme(); // drm
    },
    getPosterImage() {
      if (this.programInfo?.previewImg) {
        return this.programInfo?.previewImg;
      }
      return this.programInfo?.thumbnailUrl ? this.programInfo?.thumbnailUrl : this.programInfo?.thumbnailImage;
    },
    setPlayerEventHandler() {
      this.player.player.bigPlayButton.on(`${this.isMobile ? 'touchend' : 'click'}`, () => {
        this.keyUpSapce();
      });
      this.player.player.on('play', () => {
        this.player.player.bigPlayButton.controlText(this.getTextdata('Pause'));
      });
      this.player.player.on('pause', () => {
        this.beacon.updateLastContentDuration();
        this.player.player.bigPlayButton.controlText(this.getTextdata('Play'));
      });
      this.player.player.on('timeupdate', () => {
        this.currentTime = this.player.player.currentTime();
        this.duration = this.player.player.duration();
        this.progressPercentage = (this.currentTime / this.duration) * 100;
      });
      this.player.player.on('error', () => {
        this.errorCode = this.player.player.error().code;
        this.beacon.updateLastContentDuration();
        this.retryPlaying();
      });
      // this.player.player.on('loadeddata', () => {
      //   this.setCaptionsSettingIcon();
      //   this.setCaptionItemsAction();
      //   this.setCaptionSettingsAction();
      // });
      // this.player.player.on('loadedmetadata', () => {
      //   this.setCaptionsSettingIcon();
      // });
      // this.player.player.on('loadstart', () => {
      //   this.setCaptionsSettingIcon();
      // });
      // ios pip ??
      this.player.player.on('enterpictureinpicture', () => {});
      // Hide back-to-tab button (iOS Safari)
      this.player.player.on('webkitpresentationmodechanged', () => {
        document.documentElement.style.setProperty('--webkit-media-controls', 'hidden');
      });
      this.player.player.on('fullscreenchange', () => {
        this.$emit('fullscreenchange', this.player.player.isFullscreen());
      });
    },
    setControlUIEventHandler() {
      document.querySelector('.vjs-volume-panel').addEventListener('touchend', () => {
        let activeVolume = document.querySelector('.vjs-volume-panel').classList;
        activeVolume.contains('vjs-hover') ? clearTimeout(this.volumeToggleTime) : activeVolume.add('vjs-hover');
        this.volumeToggleTime = setTimeout(() => {
          activeVolume.remove('vjs-hover');
        }, 1000);
      });
      let backButton = document.querySelector('.video-cancel-icon');
      let progrssControlWithZoom = document.querySelector('.progess-controls');
      let controlBar = document.querySelector('#videoPlayerMain');
      controlBar.appendChild(progrssControlWithZoom);
      controlBar.appendChild(backButton);
      this.player.player.on('progress', () => {
        let tracks = this.player.player.remoteTextTracks();
        if (tracks[tracks.length - 1] && tracks[tracks.length - 1].label.indexOf('cc') > -1) {
          this.player.player.removeRemoteTextTrack(tracks[tracks.length - 1]);
        }
        // this.setCaptionsSettingIcon();
      });
      this.player.player.on('playerresize', () => {
        this.$emit('resize', {
          width: this.player.player.el_.offsetWidth,
          height: this.player.player.el_.offsetHeight,
        });
      });
    },
    setCaptionsSettingIcon() {
      if (this.channelInfo && this.channelInfo.channelNumber) {
        let captionMenuElem = document.querySelector('.vjs-subs-caps-button.vjs-control')?.children[1]?.children[0];
        let captions_setting = this.$refs.captions_setting;
        if (captionMenuElem && !captionMenuElem.contains(captions_setting)) {
          // captionMenuElem.appendChild(captions_setting);
          if (!this.iosDevice || this.iosiPad) {
            // captionMenuElem.appendChild(this.$refs.captions_setting_dialog);
          }
        }
      }
    },
    setCaptionItemsAction() {
      const { notHidden, activeText } = this.getTextTrackStatus;

      let captionOptionElems = Array.from(
        document.querySelector('.vjs-menu-item.vjs-texttrack-settings')?.parentElement?.children ?? []
      );
      let controlsAreaElem = document.querySelector('.vjs-menu-item.vjs-texttrack-settings')?.parentElement
        ?.parentElement;

      captionOptionElems.forEach((el, index) => {
        el.tabIndex = 0;
        el.addEventListener('keydown', event => {
          if (event.keyCode == 27) {
            // ESC key
            controlsAreaElem.classList.add('vjs-hidden');
            controlsAreaElem.classList.remove('vjs-lock-showing');
            event.preventDefault();
            event.stopPropagation();
          } else if (event.keyCode == 9 && activeText > 0) {
            // Tab key
            if (index === activeText) {
              event.stopPropagation();
            } else if (index === activeText + 1) {
              event.preventDefault();
              event.stopPropagation();
              let subPopup = this.$refs.captions_setting;
              subPopup.focus();
              controlsAreaElem.classList.add('vjs-lock-showing');
              controlsAreaElem.classList.remove('vjs-hidden');
            }
          }
        });
      });

      if (notHidden !== 0) return;

      // ???? X
      let captionArea = this.iosDevice
        ? document.querySelector('.vjs-subs-caps-button.vjs-control').children[1].children[0]
        : document.querySelector('.vjs-menu-item.vjs-texttrack-settings').parentElement;
      let captionListArea = this.iosDevice ? captionArea.children[0] : captionArea.children[1];
      // ????? hide
      captionListArea.style.display = 'none';
      // ??? ?? ??
      let notSuported = this.$t('components.channels.notSuported');
      let noCaption = document.createElement('div');
      noCaption.className = 'vjs-caps-noSub';
      noCaption.innerHTML = `${notSuported}.`;
      captionArea.appendChild(noCaption);
    },
    setCaptionSettingsAction() {
      // ?? ?? ??
      let subButton = document.querySelectorAll('.vjs-subs-caps-button')[1];
      subButton.addEventListener('keydown', event => {
        if (event.keyCode !== 32 && event.keyCode !== 13) return;
        // Space, Enter key
        setTimeout(() => {
          if (this.settingData.open) {
            if (this.step === 0) {
              this.$refs.text_setting_first_prev.focus();
            } else if (this.step === 1) {
              this.$refs.text_setting_second_prev.focus();
            }
          } else {
            let subPopup = this.$refs.captions_setting;
            subPopup.focus();
          }
        }, 300);
      });
    },
    setGlobalInputEventHandler() {
      window.addEventListener('click', event => {
        this.tabNavigation = false;
        let subButton = document.querySelector('.vjs-subs-caps-button');
        if (subButton !== event.target && !subButton.contains(event.target)) {
          this.ccPopFocusOut(event);
          subButton.setAttribute('aria-expanded', 'false');
        }
      });
      window.addEventListener('keydown', event => {
        if (event.keyCode === 9) {
          this.tabNavigation = true;
        }
      });
      document.querySelector('#videoPlayerMain').addEventListener('touchstart', event => {
        let subButton = document.querySelectorAll('.vjs-subs-caps-button')[1];
        let subContent = document.querySelector('.vjs-menu.vjs-lock-showing');
        if (
          subButton !== event.target &&
          !subButton.contains(event.target) &&
          subContent &&
          subContent !== event.target &&
          !subContent.contains(event.target)
        ) {
          this.ccPopFocusOut(event);
          subButton.setAttribute('aria-expanded', 'false');
        }
      });
    },
    formatTime(timeInSeconds) {
      const hours = Math.floor(timeInSeconds / 3600);
      const minutes = Math.floor((timeInSeconds % 3600) / 60);
      const seconds = Math.floor(timeInSeconds % 60);
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
      )}`;
    },
    seekTo() {
      const targetTime = (this.progressPercentage / 100) * this.duration;
      this.player.player.currentTime(targetTime);
    },
    toggleFullscreen() {
      if (this.player.player.isFullscreen()) {
        this.player.player.exitFullscreen();
      } else {
        this.player.player.requestFullscreen();
      }
    },
  },
};
</script>
<style lang="scss">
@import '../assets/css/video_player.css';
@import '~@fortawesome/fontawesome-free/css/all.css';

.vjs-error .vjs-error-display:before {
  color: #fff;
  content: var(--data-content);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5vw;
  left: 0;
  line-height: 1;
  margin-top: -0.5em;
  position: absolute;
  text-shadow: 0.05em 0.05em 0.1em #000;
  text-align: center;
  top: 45%;
  vertical-align: middle;
  width: 100%;
}
.vjs-menu .vjs-menu-content:before {
  content: var(--data-content2);
  text-transform: capitalize;
}
.vjs-menu .vjs-menu-content li {
  text-transform: capitalize;
}
.vjs-error .vjs-error-display:after {
  color: #fff;
  content: var(--data-content3);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1vw;
  left: 0;
  line-height: 1;
  margin-top: -0.5em;
  position: absolute;
  text-shadow: 0.05em 0.05em 0.1em #000;
  text-align: center;
  top: 55%;
  vertical-align: middle;
  width: 100%;
  white-space: pre-line;
}
.publish_button:hover,
.publish_button:focus,
.publish_button:active {
  background-color: #333 !important;
  background-size: cover !important;
}
.publish_button {
  background-size: cover !important;
  position: absolute;
  top: 10px;
  right: 10px;
}
.publish_button_prev {
  background-size: cover !important;
}
.vjs-lock-showing .vjs-menu-content .btn_setting {
  display: block !important;
}
.vjs-hover .vjs-menu .vjs-menu-content .btn_setting {
  display: block !important;
}
.btn_setting {
  display: none;
}

.video_container .video-js video::cue {
  color: var(--data-content4) !important;
  opacity: var(--data-content5) !important;
  font-family: var(--data-content6) !important;
  font-size: var(--data-content7) !important;

  left: 0 !important;
}
.video_container .video-js video::-webkit-media-text-track-display {
  background-color: var(--data-content8) !important;
}

.video-js {
  .vjs-icon-placeholder:before {
    content: none !important;
  }
}
.video-js {
  .vjs-big-play-button {
    .vjs-icon-placeholder:before {
      content: '\f101' !important;
    }
  }
}
.ios_video_js .vjs-waiting .vjs-poster {
  display: block;
}
.ios_video_js .video-js .vjs-volume-panel {
  width: 5em !important;
}

.ios_video_js .video-js .vjs-volume-panel .vjs-volume-control {
  display: none;
}
.ios_video_js .vjs-menu .vjs-menu-content li {
  outline: none !important;
}
.ios_video_js .vjs-subs-caps-button {
  outline: none !important;
}
.programDetails {
  padding-bottom: 2%;
  padding-left: 1%;
  .ch_number {
    padding-right: 0.5%;
    padding-left: 0%;
    margin-bottom: 2%;
    color: rgba(255, 166, 0, 0.904);
    font-size: medium;
  }
  .ch_name {
    padding: 3%;
    padding-left: 0%;
    margin-bottom: 2%;
    font-size: medium;
  }
  .ch_title {
    padding: 3%;
    padding-left: 0%;
    font-size: x-large;
    white-space: nowrap;
  }
  .ch_program_title {
    padding: 1%;
    padding-left: 0%;
    font-size: x-large;
    white-space: nowrap;
  }
  .ch_desc {
    padding: 1%;
    padding-left: 0%;
    font-size: small;
  }
  .code {
    font-size: x-small;
    border-width: 1.5px;
    border-style: solid;
    border-color: white;
    padding: 0.06%;
  }
}

.video-cancel-icon {
  position: absolute;
  top: 2%;
  cursor: pointer;
  font-size: 1.5rem;
  padding-left: 1%;
  color: #fff;
}

.progess-controls {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.top-controls {
  display: flex;
  align-items: center;
  flex: 1;
}

.watched-time,
.remaining-time {
  color: white;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 4px;
}

.remaining-time {
  margin-left: 10px;
}

.vod-progress-bar {
  flex: 1;
}

.vod-progress {
  width: 100%;
}

.VOD_container {
  .video-js .vjs-control-bar {
    top: calc(1vh + 5%);
    background: none;
    padding: 0;
  }
  .video-js .vjs-fullscreen-control {
    display: none;
  }
  .vjs-playback-rate-value {
    top: 8px;
  }

  .vjs-subs-caps-button {
    position: relative;
  }

  .fullscreen-control button {
    background: none;
    border: none;
    font-size: 20px;
    color: white;
    cursor: pointer;
  }

  .vjs-menu {
    top: 386%;
    left: -58%;
  }

  .vjs-live-control {
    display: none;
  }

  .custom-left-controls {
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 5px;
    padding: 17px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1020px) {
  .video-cancel-icon {
    position: absolute;
    top: 5%;
    cursor: pointer;
    font-size: 1.5rem;
    padding-left: 0;
    color: #fff;
  }

  .video-cancel-icon:hover {
    color: red;
  }

  .VOD_container {
    .video-js .vjs-control-bar {
      top: calc(1vh + 8%);
    }

    .vjs-playback-rate {
      display: block !important;
    }

    .video-js .vjs-big-play-button {
      font-size: 2em;
    }
    .custom-left-controls {
      left: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .VOD_container {
    .video-js .vjs-control-bar {
      top: calc(1vh + 18%);
    }

    .video-js .vjs-big-play-button {
      font-size: 1.3em;
    }
    .vjs-menu {
      top: 160%;
    }
  }
}
</style>
