<template>
  <div class="carousel-controls VOD-carousel" v-if="carouselItems && carouselItems.length > 0">
    <div class="carousel">
      <button @click="prevImage" class="prev-btn desktop-only-content">&#10094;</button>
      <button
        class="carousel-image"
        :style="{
          border: '2px solid gray',
          backgroundImage: isVideoPlaying ? '' : `url(${currentImage.thumbnailImg})`,
        }"
        @click="watchNow"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <div class="carousel-overlay" @click="watchNow">
          <div class="carousel-content">
            <div>
              <h2>{{ currentImage.title }}</h2>
            </div>
            <div>
              <p>
                {{ currentImage.releaseDate }}
                {{ currentImage.rating == 'off' ? '' : currentImage.rating }}
                {{ currentImage.genreName }}
                {{ calculateDurationInHHMM(currentImage.duration) }}
                {{ currentImage.captionFlag ? 'CC' : '' }}
              </p>
            </div>
            <div>
              <p v-if="currentImage.directors || currentImage.actors" class="desktop-only-content">
                {{
                  currentImage.directors
                    ? currentImage.directors != ('null' || '')
                      ? 'Director - ' + currentImage.directors
                      : ''
                    : ''
                }}
                <br />
                {{
                  currentImage.actors
                    ? currentImage.actors != ('null' || '')
                      ? 'Cast - ' + currentImage.actors
                      : ''
                    : ''
                }}
              </p>
            </div>
            <div style="overflow: hidden" class="desktop-only-content">
              <p class="text-trim-ellipsis">{{ currentImage.description }}</p>
            </div>
          </div>
          <div class="thumbnail-container desktop-only-content">
            <video
              id="featured_category_video"
              ref="featured_category_video"
              autoplay="true"
              preload="none"
              muted
              data-setup='{"loop": true}'
            ></video>
          </div>
        </div>
      </button>
      <button @click="nextImage" class="next-btn desktop-only-content">&#10095;</button>
    </div>
    <div class="carousel-dots">
      <span
        v-for="(item, index) in carouselItems.slice(0, 5)"
        :key="index"
        @click="goTo(index)"
        class="dot"
        :class="{ active: currentImageIndex === index }"
      ></span>
    </div>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import videoMixin from '../mixins/video';
import eventBus from '../event-bus';

export default {
  name: 'FeaturedCarousel',
  mixins: [videoMixin],
  computed: {
    currentImage() {
      return this.carouselItems[this.currentImageIndex] || {};
    },
  },
  props: {
    carouselItems: Array,
  },
  data() {
    return {
      currentImageIndex: 0,
      // intervalId: null,
      player: null,
      isVideoPlaying: false,
      touchStartX: 0,
      touchEndX: 300,
    };
  },
  created() {
    this.$watch('currentImage', (newUrl, oldUrl) => {
      if (newUrl !== oldUrl && this.player) {
        this.player.src(this.getUrlWithSSAIParameters(newUrl.mediaUrl));
        this.player.poster(newUrl.thumbnailImg);
        this.player.play();
      }
    });
  },
  methods: {
    // startCarousel() {
    //   this.intervalId = setInterval(this.nextImage, 5000);
    // },
    // stopCarousel() {
    //   clearInterval(this.intervalId);
    // },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    // handleTouchMove(event) {
    //   this.touchEndX = event.touches[0].clientX;
    // },
    handleTouchEnd() {
      const touchDiff = this.touchEndX - this.touchStartX;
      console.log('touchDiff', touchDiff);
      if (touchDiff > 20) {
        this.prevImage();
      } else if (touchDiff < -20) {
        this.nextImage();
      }
    },
    calculateDurationInHHMM(totalSeconds) {
      const totalMinutes = Math.floor(totalSeconds / 60);
      return totalMinutes
        ? totalMinutes / 60 > 0
          ? `${totalMinutes % 60}m`
          : `${Math.floor(totalMinutes / 60)}h ${totalMinutes % 60}m`
        : '';
    },
    prevImage() {
      // this.stopCarousel();
      this.currentImageIndex = (this.currentImageIndex - 1 + this.carouselItems.length) % this.carouselItems.length;
      // this.startCarousel();
      this.runPlayerOnlyOnDesktop();
    },
    nextImage() {
      // this.stopCarousel();
      this.currentImageIndex = (this.currentImageIndex + 1) % this.carouselItems.length;
      // this.startCarousel();
      this.runPlayerOnlyOnDesktop();
    },
    changeImage(index) {
      // this.stopCarousel();
      this.currentImageIndex = index;
      this.runPlayerOnlyOnDesktop();
      // this.startCarousel();
    },
    runPlayerOnlyOnDesktop() {
      if (!this.isMobile()) {
        this.setPlayerSourceData();
      }
    },
    watchNow() {
      this.stopPlayWindow();
      eventBus.$emit('disposePlayer');
      this.$emit('openHalfPlayer', this.currentImage, true);
    },
    goTo(index) {
      this.currentImageIndex = index;
    },
    hoverMouseOnCard() {
      this.isVideoPlaying = true;
      this.setPlayerSourceData();
    },
    unhoverMouseOnCard() {
      this.isVideoPlaying = false;
      this.stopPlayWindow();
    },
    setPlayerSourceData() {
      if (this.player) {
        this.player.poster(this.currentImage.thumbnailImg);
        this.player.src(this.getUrlWithSSAIParameters(this.currentImage.mediaUrl));

        // Add an event listener for when the video is buffering
        this.player.on('waiting', () => {
          this.player.posterImage.show();
        });
        // Add an event listener for when the video is loaded and can play
        this.player.on('canplay', () => {
          // this.player.posterImage.hide();
          this.reRunPlayer();
        });
      }
    },
    startPlayWindow() {
      const videoOptions = {
        controls: false,
      };
      setTimeout(() => {
        this.player = videojs(this.$refs.featured_category_video, videoOptions);
        this.player.poster(this.currentImage.thumbnailImg);
        this.runPlayerOnlyOnDesktop();
      }, 2000);
    },
    reRunPlayer() {
      this.player.play();
    },
    stopPlayWindow() {
      if (this.player) {
        this.player.pause();
        this.player.posterImage.show();
        // this.player.dispose();
      }
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  mounted() {
    // this.startCarousel();
    if (!this.isMobile()) {
      this.startPlayWindow();
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style lang="scss">
.carousel-item {
  position: relative;
  flex: 0 0 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}

p {
  margin: 10px 0;
}

button {
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.carousel {
  margin: 2% 0;
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  overflow: hidden;
}

.carousel-image {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
}

.carousel-overlay {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  // flex-direction: row;
  // align-items: center;
  // border: 2px solid white;
  // border-radius: 20px;
}

.carousel-content {
  // flex-basis: 50%;
  text-align: left;
  padding: 20px;
  color: #fff;
  height: 100%;
  flex: 1;
  border-radius: 20px;
}

.carousel-content h2 {
  font-size: 28px;
  top: 0;
  margin-bottom: 10px;
}

.carousel-content p {
  margin-bottom: 20px;
  top: 0;
}

.carousel-content button {
  color: #fff;
  font-size: 24px;
  width: 200px;
  background-color: #808080 !important;
  position: absolute;
  bottom: 20px;
  border-radius: 6px;
}

.carousel-controls {
  left: 0;
  right: 0;
}

.carousel-controls button {
  background-color: #0b0b0b;
  color: #fdfcfc;
  border: none;
}

.thumbnail-container {
  // display: flex;
  // flex-direction: column;
  justify-content: center;
  // cursor: pointer;
  // background-repeat: no-repeat;
  // // background-size: 50% 90%;
  // background-position: center;
  // height: 90%;
  // width: 50%;
  position: relative;
  align-items: center;
  flex: 1;
  border-radius: 20px;
}

// .thumbnail-container div,
.thumbnail-container video,
.featured_category_video-dimensions {
  height: 100%;
  width: auto;
  border-radius: 20px;
}
.thumbnail-container video {
  width: 100%;
}

.thumbnail-container .vjs-paused .vjs-poster {
  display: none;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
}

.dot.active {
  background-color: #007bff;
}

.carousel-content .text-trim-ellipsis {
  -webkit-line-clamp: 5;
}

.VOD-carousel {
  word-break: break-word;
  .vjs-paused .vjs-text-track-display,
  .vjs-user-active .vjs-text-track-display {
    background-color: unset;
  }

  .vjs-loading-spinner .vjs-control-text {
    display: none !important;
  }

  .carousel::-webkit-scrollbar {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .VOD-carousel {
    margin: 34% 0 10% 0;
  }
  .carousel {
    height: 225px;
  }
  .content_s {
    padding: 0;
    width: 102vw;
  }
  .carousel-content {
    flex-basis: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
  }
  .carousel-content h2,
  .carousel-content p {
    font-size: 16px;
    margin-bottom: 0;
  }
  .desktop-only-content {
    display: none;
  }
  .carousel-controls button {
    padding: 8px 3px;
  }
  .carousel-image {
    margin: 0 10px;
  }
}
</style>
