<template>
  <div class="unsupported_contents">
    <div class="terms" style="color:white">
      <h2>Browser Not Supported!</h2>
      <div class="guide">
        Browser not supported<br />
        We recommend using LG Channels with one of the following browsers:
      </div>
      <div class="btn_unsupported">
        <button
          v-for="(type, idx) in browsers"
          :key="idx"
          class="btn"
          :class="`btn_${type.toLowerCase()}`"
          @click="window.open(this.downloadLinks[type], '_blank')"
        >
          {{ type }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      browsers: ['Edge', 'Chrome', 'Safari', 'Firefox'],
      downloadLinks: {
        Edge: 'https://www.microsoft.com/edge',
        Chrome: 'https://www.google.com/chrome',
        Safari: 'https://support.apple.com/downloads/safari',
        Firefox: 'https://www.mozilla.org/firefox/new/',
      },
    };
  },
};
</script>

<style lang="scss">
.v-application--wrap {
  background-color: black !important;
  font-family: 'LG SUXR', sans-serif !important;
}
</style>
