// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ico/Spinner_black.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".videoArea{background-color:#000}.mobile_scroll .category{z-index:1}.category{z-index:0}.ui-spinner{display:block;position:absolute;top:50%;left:50%;margin:-25px 0 0 -25px;width:50px;height:50px;opacity:.85;border-radius:50%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:50px;background-position:50%;background-repeat:no-repeat}@media screen and (max-width:480px){.VODIsEnabledLive #playerLayout{top:115px!important}.VODIsEnabledLive .tvguide_area{padding-top:400px!important}}", ""]);
// Exports
module.exports = exports;
