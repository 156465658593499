<template>
  <div
    class="contents clear"
    :class="{ VODIsEnabledLive: isVODStartSupport }"
    :style="{ height: `${screenSize.height - 1}px`, paddingBottom: `${getContentsPaddingBottom}px` }"
  >
    <!-- 비디오 -->
    <div
      v-show="isPlayerUIReady"
      class="videoArea live_area"
      :style="getLiveAreaStyle"
      id="playerLayout"
      ref="live_area"
      wrap
      justify-center
    >
      <div class="fullMode video_wrap">
        <!-- 플레이어 -->
        <video-player
          id="mainPlayer"
          class="vjs-big-play-centered changed_height_video_container"
          ref="video_container"
          :player="player"
          :channelInfo="channelInfo"
          :programInfo="programInfo"
          style="display:flex"
          @resize="videoResized"
          @fullscreenchange="videoFullscreenChanged"
        />
      </div>
      <!-- 재생 영상 정보 -->
      <programInfo
        class="live_status main"
        ref="live_status"
        :style="{ display: programInfoDisplay }"
        :channelInfo="channelInfo"
        :programInfo="programInfo"
        :programInfoDisplay="programInfoDisplay"
        @focustab="focusToTab"
      ></programInfo>
    </div>
    <div
      v-if="isPlayerUIReady"
      class="tvguide_area"
      :class="{ mobile_scroll: mobileScrolling }"
      :style="{ paddingTop: `${tvguide_paddingTop}px` }"
    >
      <!-- 카테고리 탭 -->
      <categoryTab
        v-if="is_category_ready"
        class="category"
        :style="{ top: `${getCategoryTop}px` }"
        :tabs="tabs"
        :nextTab="nextTab"
        :forceFocus="focusTab"
        @tabposition="tabPositionChanged"
      ></categoryTab>
      <!-- 채널 리스트 -->
      <channels
        v-if="is_channel_ready"
        class="guide_list_wrap"
        :categories="tabs.nowCategory"
        :selectedTab="tabs.selected"
        :player="player"
        :channelInfo="channelInfo"
        :programInfo="programInfo"
        :searchedChannel="searchedChannel"
        :tabBottom="tabBottom"
        :isFullscreen="isVideoFullsized"
        @changechannel="changechannel"
        @openedProgramPopup="openedProgramPopup"
      >
      </channels>
    </div>
    <!-- 로딩 Spinner -->
    <div v-if="!isPlayerUIReady" class="ui-spinner" />
    <!-- 약관 화면 -->
    <termLayout v-if="isAgreed === false"></termLayout>
    <!-- 에러 팝업 -->
    <errorPopUps v-if="errorPopUpData.open" :popUpData="errorPopUpData" />
  </div>
</template>

<script>
import { categoryTab, VideoPlayer, channels, programInfo, termLayout } from '@/components/index';
import SI from '@/apis/service_interface';
import errorPopUps from '../../components/errorPopUps.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'VideoExample',
  components: {
    VideoPlayer,
    categoryTab,
    channels,
    programInfo,
    termLayout,
    errorPopUps,
  },
  inject: ['beacon'],
  props: {
    header_height: Number,
    footer_height: Number,
  },
  data() {
    return {
      is_category_ready: false,
      is_channel_ready: false,
      player: { player: null, textSettingHegiht: '' }, // 플레이어 데이터
      // 재생 중인 채널 정보
      channelInfo: {},
      // 재생 중인 프로그램 정보
      programInfo: {},
      // 채널 data
      tabs: {
        selected: { index: 0, timestamp: 0 }, // 현재 탭 index
        nowCategory: [], // 현재 탭
        categories: [], // 전체 탭
      },
      // 검색해서 온 채널
      searchedChannel: {},

      programsTimer: {
        id: 0,
        delay: 1 * 1000,
      },
      updatingEpg: false,
      guaranteedSchedule: 6 * 60 * 60 * 1000, //  6 시간 (milliseconds)
      scheduleTimestamp: this.$moment(new Date()),
      errorPopUpData: {
        open: false,
        code: 0,
      },
      nextTab: {
        index: -1,
        timestamp: 0,
      },
      // style method
      programInfoDisplay: 'block',
      mobileScrolling: false,
      tvguide_paddingTop: 0,
      tabBottom: 0,
      focusTab: {},
      videoSize: { width: 0, height: 0 },
      isVideoFullsized: false,
      isVODStartSupport: false,
    };
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
      screenMode: 'getScreenMode',
      popupStatus: 'getPopupStatus',
      isAgreed: 'getAgreed',
    }),
    getComponentHeight() {
      return whatitem => {
        switch (whatitem) {
          case 'live_status':
            return this.$refs.live_status?.$el?.offsetHeight ?? 0;
          case 'live_area':
            return this.$refs.live_area?.offsetHeight ?? 0;
          case 'header':
            return this.header_height;
          case 'footer':
            return this.footer_height;
          default:
            return document.querySelector(`.${whatitem}`)?.offsetHeight ?? 0;
        }
      };
    },
    getLiveAreaStyle() {
      if (!this.is_category_ready || !this.is_channel_ready) return {};
      if (this.screenMode === 'landscape') {
        return {
          position: 'relative',
          top: '0px',
        };
      } else {
        return {
          position: 'fixed',
          top: `${this.getComponentHeight('header') - 1}px`,
        };
      }
    },
    getContentsPaddingBottom() {
      if (!this.is_category_ready || !this.is_channel_ready) return 0;
      return this.footer_height;
    },
    getCategoryTop() {
      return this.programInfoDisplay === 'block' ? 0 : this.getComponentHeight('header') + this.videoSize.height - 2;
    },
    isPlayerUIReady() {
      return !this.errorPopUpData.open && this.is_category_ready && this.is_channel_ready;
    },
  },
  watch: {
    popupStatus: {
      deep: true,
      handler(newVal) {
        if (newVal.type === 'error' && newVal.open !== this.errorPopUpData.open) {
          this.errorPopUpData = {
            open: newVal.open,
            code: newVal.code,
          };
        }
      },
    },
    screenSize: {
      deep: true,
      handler() {
        this.initializeLayout();
      },
    },
    mobileScrolling: {
      handler(newVal) {
        this.programInfoDisplay = newVal ? 'none' : 'block';
        if (this.screenMode === 'portrait') {
          setTimeout(() => {
            this.tvguide_paddingTop = this.getComponentHeight('live_area');
          }, 100);
        }
      },
    },
    isPlayerUIReady: {
      handler(newVal) {
        this.initializeLayout();
        this.$emit('uiStatus', newVal);
      },
    },
  },
  async mounted() {
    let response = await this.initializeEpgList();
    if (!response) return;

    this.$store.state.country = (await SI.getCountry()) ?? '';

    this.programsTimer.id = setInterval(() => {
      this.expiredProgramsCheck();
    }, this.programsTimer.delay);

    // beacon
    await this.beacon.initBeaconInfo();

    this.isVODStartSupport = localStorage.getItem('VODSupport') == 'Y' ? true : false;

    // Event listener 등록
    this.setEventHandlers();

    this.is_category_ready = true;
    this.is_channel_ready = true;
  },
  methods: {
    setUILandscape() {
      this.mobileScrolling = false;
      this.tvguide_paddingTop = 0;

      setTimeout(() => {
        // video height
        this.$store.dispatch(
          'setVideoHeight',
          `${this.getComponentHeight('live_area') - this.getComponentHeight('live_status')}px`
        );

        // text setting height
        if (this.screenSize.width > 1000) {
          this.player.textSettingHeight = this.videoSize.height;
        } else {
          this.player.textSettingHeight = `${this.screenSize.height * 0.6}px`;
        }
      }, 100);
    },
    setUIPortrait() {
      // video height
      this.$store.dispatch('setVideoHeight', '');

      // text setting height
      this.player.textSettingHeight = `${this.screenSize.height * 0.6}px`;

      document.body.classList.remove('wid');

      // live area
      setTimeout(() => {
        this.tvguide_paddingTop = this.getComponentHeight('live_area');
      }, 100);
    },
    videoResized(_size) {
      this.videoSize = _size;
    },
    videoFullscreenChanged(_isFullscreen) {
      this.isVideoFullsized = _isFullscreen;
      if (!_isFullscreen) {
        this.initializeLayout();
      }
    },
    setEventHandlers() {
      // scroll event
      window.addEventListener('scroll', () => {
        if (this.screenMode === 'portrait') {
          this.mobileScrolling = window.scrollY > this.getComponentHeight('live_status');
        } else {
          this.tvguide_paddingTop = 0;
        }
      });
      // 검색 후 처리
      this.$appbus.$on('searchedChannel', params => {
        // 검색해서 온 채널을 재생할 땐 All 탭으로 변경
        if (this.tabs.selected.index !== 0) {
          // 탭 변경
          this.$nextTick(() => {
            this.nextTab = {
              index: 0,
              timestamp: Date.now(),
            };
          });
        }
        setTimeout(() => {
          // 채널 변경
          let channel = this.tabs.categories[0].channels.find(ch => {
            return ch.channelId === params.channelId;
          });
          if (channel) {
            this.searchedChannel = {
              ...channel,
              timestamp: Date.now(),
              eventType: params.eventType,
            };
          }
        }, 500);
      });
    },
    initializeLayout() {
      if (!this.isPlayerUIReady || this.isVideoFullsized) return;
      if (this.screenMode === 'landscape') {
        this.setUILandscape();
      } else {
        this.setUIPortrait();
      }
    },
    tabPositionChanged(value) {
      this.tabBottom = value.bottom;
    },
    focusToTab() {
      this.focusTab = { focus: true, timestamp: new Date().getTime() };
    },
    //삭제 필요 코드
    adjustData(epgListData, duration = 30) {
      if (!SI.mock) return;
      epgListData.categories.forEach(category => {
        category.channels.forEach((e, idx) => {
          // 정상동작을 위해 mock 데이터에서는 mediaurl, programId 조정
          e.mediaStaticUrl =
            'https://mobtv-lg-us.amagi.tv/hls/amagi_hls_data_mobtvAAAA-mobtv-lg/CDN/master.m3u8?ads.deviceid=[DEVICE_ID]&ads.ifa=[IFA]&ads.ifatype=[IFA_TYPE]&ads.lat=[LMT]&ads.donotsell=[DNS]&ads.ua=[UA]&ads.ip=[IP]&ads.gdpr=[GDPR]&ads.gdprconsent=[GDPR_CONSENT]&ads.country=[COUNTRY]&ads.usprivacy=[US_PRIVACY]&ads.appstoreurl=[APP_STOREURL]&ads.bundleid=[APP_BUNDLE]&ads.appname=[APP_NAME]&ads.appversion=[APP_VERSION]&ads.devicetype=[DEVICE_TYPE]&ads.devicemake=[DEVICE_MAKE]&ads.devicemodel=[DEVICE_MODEL]&ads.targetad=[TARGETAD_ALLOWED]';
          e.programs.forEach((el, i) => {
            el.startDateTime = this.$moment(new Date()).add(i * duration, 'minutes');
            el.endDateTime = this.$moment(new Date()).add((i + 1) * duration, 'minutes');
            el.programId = `${idx}${i}`;
          });
        });
      });
    },

    async getEpgListData(channelId) {
      this.updatingEpg = true;
      let data = await SI.schedulelist(channelId);
      if (data?.result) {
        this.updatingEpg = false;
        return data.value;
      } else {
        return null;
      }
    },

    updateErrorStatus(code) {
      this.$store.dispatch('setPopupStatus', {
        type: 'error',
        open: true,
        code: code,
      });
    },

    async initializeEpgList() {
      let epgListData = await this.getEpgListData();
      if (!epgListData) return false;

      this.scheduleTimestamp = this.$moment(new Date());

      this.tabs.categories = epgListData.categories;

      // 전체채널 카테고리 추가
      let allChannel = [];
      try {
        for (let category of this.tabs.categories) {
          try {
            for (let channel of category.channels) {
              // beacon
              channel.categoryCode = category.categoryCode;
              channel.categoryName = category.categoryName;
              if (!allChannel.includes(channel)) {
                allChannel.push(channel);
              }
            }
          } catch {
            console.error('[initializeEpgList] category channel loop error');
            category.channels = [];
          }
        }
      } catch {
        console.error('[initializeEpgList] category loop error');
        this.tabs.categories = [];
        this.updateErrorStatus(200);
      }
      // 채널번호 기준 전체채널 sort
      allChannel = allChannel.sort((a, b) => {
        return +a.channelNumber - +b.channelNumber;
      });
      let index = this.tabs.categories.findIndex(category => category.categoryCode === 'all');
      if (index < 0) {
        this.tabs.categories.unshift({
          categoryName: 'All',
          categoryCode: 'all',
          channels: allChannel,
        });
      } else {
        this.tabs.categories[index].channels = allChannel;
      }
      this.$store.state.channelList = this.tabs.categories[0].channels;

      this.tabs.selected.index = this.tabs.nowCategory?.length > 0 ? this.tabs.selected.index : 0;
      this.tabs.selected.timestamp = new Date().getTime();
      this.tabs.nowCategory = [this.tabs.categories[this.tabs.selected.index]];
      this.adjustData(epgListData);
      return true;
    },

    expiredProgramsCheck() {
      if (this.tabs.categories[0].categoryCode !== 'all') {
        console.error('[live] all category is not ready');
        return;
      }

      let flag = false;
      for (let channel of this.tabs.categories[0].channels) {
        let now = this.$moment(new Date());
        let firstProgramIndex = channel.programs.findIndex(program =>
          now.isBetween(this.$moment(program.startDateTime), this.$moment(program.endDateTime))
        );
        if (firstProgramIndex >= 1) {
          // 과거 프로그램 삭제
          channel.programs.splice(0, firstProgramIndex);
          if (channel.channelId === this.channelInfo.channelId) {
            this.updateProgramInfo(channel.programs[0]);
          }
        } else if (firstProgramIndex < 0) {
          // 유효한 데이터가 없으면서 마지막 업데이트된 시간이 guaranteedSchedule 이상 지났으면
          let lastUpdateTime = now.diff(this.scheduleTimestamp);
          if (lastUpdateTime >= this.guaranteedSchedule) flag = true;
        }
      }

      if (flag && !this.updatingEpg) this.initializeEpgList();
    },

    async remainDurationCheck(channelId) {
      // 데이터 구조 : tabs > categories > channels > channel > programs > program
      if (this.tabs.categories[0].categoryCode !== 'all') {
        console.error('[live] all category is not ready');
        return;
      }

      let targetChannel = this.tabs.categories[0].channels.find(_channel => _channel.channelId === channelId);

      if (!targetChannel || targetChannel.programs.length === 0) return;

      let now = this.$moment(new Date());
      let lastProgram = targetChannel.programs[targetChannel.programs.length - 1];
      let remainDuration = lastProgram ? this.$moment(lastProgram.endDateTime).diff(now) : 0;

      if (remainDuration > this.guaranteedSchedule) return;

      // 1. 서버 데이터에서 신규 스케줄 가져오기
      let epgListData = await this.getEpgListData(channelId);

      // 2. 기존 tab, category, channel 정보 업데이트 하기
      for (let category of epgListData.categories) {
        let schedule = category.channels.find(c => c.channelId === channelId);
        if (schedule) {
          this.updateChannelSchedule(channelId, schedule);
          return;
        }
      }
    },

    changechannel(channel) {
      this.channelInfo = channel;
      if (!channel) {
        this.updateProgramInfo(null);
      } else {
        this.channelInfo.channelSessionId = this.beacon.getRandomSessionId();
        this.updateProgramInfo(channel.programs[0]);
      }
    },

    updateProgramInfo(program) {
      if (program) {
        program.contentSessionId = this.beacon.getRandomSessionId();
      }
      this.programInfo = program;
      this.$store.state.programInfo = program;
    },

    openedProgramPopup(id) {
      this.remainDurationCheck(id);
    },

    updateChannelSchedule(channelId, schedule) {
      for (let category of this.tabs.categories) {
        let channel = category.channels.find(c => c.channelId === channelId);
        if (channel) {
          let nowTime = this.$moment(new Date());
          channel.programs = schedule.programs.filter(rawProgram => {
            return this.$moment(new Date(rawProgram.endDateTime)).isAfter(nowTime);
          });
          return;
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.programsTimer.id);
    // beacon
    this.beacon.stopBeaconInterval();
  },
};
</script>
<style lang="scss">
.videoArea {
  background-color: black;
}
.mobile_scroll .category {
  z-index: 1;
}
.category {
  z-index: 0;
}
.ui-spinner {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  opacity: 0.85;
  border-radius: 50%;
  background-image: url('../../assets/images/ico/Spinner_black.gif');
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 480px) {
  .VODIsEnabledLive {
    #playerLayout {
      top: 115px !important;
    }
    .tvguide_area {
      padding-top: 400px !important;
    }
  }
}
</style>
