<template>
  <div
    class="contents content_s clear"
    :style="{
      height: `${screenSize.height - 56}px`,
      paddingBottom: `${getContentsPaddingBottom}px`,
      color: 'white',
      overflowY: 'scroll',
    }"
  >
    <featuredCarousel
      ref="featured_carousel"
      :carouselItems="carouselItems"
      @openHalfPlayer="openHalfPlayer"
      @closeHalfPlayer="closeHalfPlayer"
    ></featuredCarousel>

    <Categories
      :channelList="channelList"
      :continueWatchItems="continueWatchItems"
      :favoriteItems="favoriteItems"
      @openHalfPlayer="openHalfPlayer"
      @closeHalfPlayer="closeHalfPlayer"
      @addToRecentlyWatched="addToRecentlyWatched"
      @addToFavoriteVOD="addToFavoriteVOD"
    ></Categories>

    <!-- Player pop up -->
    <halfPlayer
      :showPlayerPopup="showPlayerPopup"
      :videoData="videoData"
      :seasonData="seasonData"
      :relatedVideos="relatedVideos"
      @addToFavoriteVOD="addToFavoriteVOD"
      @openHalfPlayer="openHalfPlayer"
      @closeHalfPlayer="closeHalfPlayer"
      @updateSeasonVod="updateSeasonVod"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SI from '@/apis/service_interface';
import featuredCarousel from '../../components/featuredCarousel.vue';
import Categories from '../../components/categories.vue';
import halfPlayer from '../../components/halfPlayer.vue';

export default {
  name: 'OnDemand',
  components: {
    featuredCarousel,
    Categories,
    halfPlayer,
  },
  props: {
    header_height: Number,
    footer_height: Number,
  },
  computed: {
    ...mapGetters({
      screenSize: 'getScreenSize',
    }),
    getContentsPaddingBottom() {
      return this.footer_height;
    },
  },
  data() {
    return {
      tabs: {
        selected: { index: 0, timestamp: 0 },
        nowCategory: [],
        categories: [],
      },
      carouselItems: [],
      channelList: [],
      continueWatchItems: {},
      favoriteItems: {},

      showPopup: false,
      selectedProgram: {},
      selectedProgramBackgroundUrl: '',
      showPlayerPopup: false,
      videoData: {},
      relatedVideos: {},
      seasonData: {},
      displayPip: false,
      callLocalAPI: false,
    };
  },

  async mounted() {
    this.callLocalAPI = localStorage.getItem('mockup') == 'Y' ? true : false;
    this.getAllOnDemandList();
    if (this.$store.state.playSrc) {
      this.displayPip = true;
    }
  },
  methods: {
    async getAllOnDemandList() {
      let recentlyWatched = JSON.parse(localStorage.getItem('recently-watched')) || [];
      let favoriteVOD = JSON.parse(localStorage.getItem('favorite-vod')) || [];
      const params = { tabId: 'T-TVSHOW' };
      let onDemandResponse, ondemandTabData;

      onDemandResponse = this.callLocalAPI ? await SI.ondemandlist() : await SI.ondemandlist(params);
      // onDemandResponse = await SI.ondemandlist(params);

      ondemandTabData = onDemandResponse.value.tabs && onDemandResponse.value.tabs[0];

      if (ondemandTabData && ondemandTabData.topBanner) {
        this.carouselItems = ondemandTabData.topBanner && ondemandTabData.topBanner.contents;
      } else {
        // local api call for featured carousel
        let onDemandResponse = await SI.ondemandlist();
        let ondemandTabData = onDemandResponse.value.tabs && onDemandResponse.value.tabs[0];
        this.carouselItems = ondemandTabData.topBanner && ondemandTabData.topBanner.contents;
      }

      this.channelList = ondemandTabData.categories;

      this.continueWatchItems = {
        categoryName: 'Recently Watched',
        contents: recentlyWatched,
      };
      this.favoriteItems = {
        categoryName: 'Favorites',
        contents: favoriteVOD,
      };
    },
    addToRecentlyWatched(program) {
      // Get the current recently-watched data from local storage
      let recentlyWatched = JSON.parse(localStorage.getItem('recently-watched')) || [];
      program.contentId = program.contentId || program.vodId || program.seasonId;

      // Check if the card with the same contentId already exists in the recently-watched list
      const existingIndex = recentlyWatched.findIndex(item => item.contentId === program.contentId);

      if (existingIndex !== -1) {
        // If the card exists, remove it to prevent duplicates
        recentlyWatched.splice(existingIndex, 1);
      } else if (recentlyWatched.length >= 50) {
        // If the recently-watched list exceeds the maximum length, remove the oldest item
        recentlyWatched.pop();
      }

      // Add the current card data to the beginning of the array
      recentlyWatched.unshift(program);

      // Save the updated recently-watched data back to local storage
      localStorage.setItem('recently-watched', JSON.stringify(recentlyWatched));

      // Assign the recentlyWatched content for list
      this.continueWatchItems.contents = recentlyWatched;
    },
    addToFavoriteVOD(program) {
      // Get the current favorite-vod data from local storage
      let favoriteVOD = JSON.parse(localStorage.getItem('favorite-vod')) || [];
      program.contentId = program.contentId || program.vodId || program.seasonId;

      // Check if the card with the same contentId already exists in the favorite-vod list
      const existingIndex = favoriteVOD.findIndex(item => item.contentId === program.contentId);

      if (existingIndex !== -1) {
        // If the card exists, remove it to prevent duplicates
        favoriteVOD.splice(existingIndex, 1);
      } else if (favoriteVOD.length >= 20) {
        // If the recently-watched list exceeds the maximum length, remove the oldest item
        favoriteVOD.pop();
        favoriteVOD.unshift(program);
      } else {
        // Add the current card data to the beginning of the array
        favoriteVOD.unshift(program);
      }

      // Save the updated favorite-vod data back to local storage
      localStorage.setItem('favorite-vod', JSON.stringify(favoriteVOD));

      // Assign the favorite-vod content for list
      this.favoriteItems.contents = favoriteVOD;
    },
    focusToTab() {
      this.focusTab = { focus: true, timestamp: new Date().getTime() };
    },
    async openHalfPlayer(program, isFeaturedCarousel) {
      this.$refs.featured_carousel.stopPlayWindow();
      let params = {};
      let programData = [];

      if (isFeaturedCarousel) {
        const vodData = await SI.vodDetails();
        programData = { ...vodData.value, ...program };
      } else if (program.contentType === 'TVSHOW-SERIES') {
        params = { seasonId: program.seasonId };
        const seasonData = this.callLocalAPI ? await SI.seasonDetails() : await SI.seasonDetails(params);
        programData = { ...seasonData.value, ...program };
        programData['contentType'] = program.contentType;
        this.seasonData = this.callLocalAPI ? programData.data : programData;
      } else {
        params = { vodId: program.contentId || program.vodId };
        const vodData = this.callLocalAPI ? await SI.vodDetails() : await SI.vodDetails(params);
        programData = { ...vodData.value, ...program };
      }

      this.videoData = programData;
      this.showPlayerPopup = true;
      this.changeDisplayPIP();
    },
    async fetchSuggestionOrSeasonData(program) {
      if (program.contentType === 'MOVIE') {
        let suggestionList = await SI.suggestionlist();
        this.relatedVideos = suggestionList.value.suggestion[0];
      } else if (program.contentType === 'TVSHOW') {
        let season = await SI.seasonDetails();
        this.seasonData = season.value.data;
      }
    },
    closeHalfPlayer(programInfo, percentage, watchedTime) {
      programInfo.watchedPercentage = percentage || 0;
      programInfo.watchedTime = watchedTime || 0;
      this.showPlayerPopup = false;
      this.addToRecentlyWatched(programInfo);
      this.$refs.featured_carousel.reRunPlayer();
    },
    changeDisplayPIP() {
      this.displayPip = false;
    },
    async updateSeasonVod(season) {
      const params = { seasonId: season.seasonId };
      await SI.seasonDetails(params).then(data => {
        if (data.value.seasonInfo) {
          let episodeData = data.value.seasonInfo[0].episodeInfo;
          this.seasonData.seasonInfo = this.seasonData.seasonInfo.map(item => {
            if (item.seasonNumber == season.seasonNumber) {
              return { ...item, episodeInfo: episodeData };
            }
            return { ...item, episodeInfo: null };
          });
        }
      });
    },
  },
};
</script>
