<template>
  <div id="wrap" class="wrap pusblish-apply">
    <div class="">
      <!-- header -->
      <header class="info_header">
        <div class="info_header_inner">
          <h1>
            <div class="hide">{{ getTextdata('header_text1') }}</div>
          </h1>
          <div class="controls">
            {{ getTextdata('header_text2') }}
          </div>
        </div>
      </header>
      <!-- // header -->

      <!-- contents -->
      <div class="info_contents">
        <!-- Premium Live Channels -->
        <section class="section01">
          <div class="section_inner">
            <div class="row pc-hide">
              <img src="../../assets/images/info/section01_img@3x.png" alt="" />
            </div>
            <div class="row">
              <dl>
                <dt>{{ getTextdata('section1_text1') }}</dt>
                <dd>{{ getTextdata('section1_text2') }}</dd>
              </dl>
              <div class="button_area">
                <button class="btn_link" @click="toChannelList">{{ getTextdata('section1_text3') }}</button>
              </div>
            </div>
            <div class="row mo-hide">
              <img src="../../assets/images/info/section01_img@3x.png" alt="" />
            </div>
          </div>
        </section>
        <!-- // Premium Live Channels -->

        <!-- On-Demand -->
        <section class="section02">
          <div class="section_inner">
            <div class="row">
              <img src="../../assets/images/info/section02_img@3x.png" alt="" />
            </div>
            <div class="row">
              <dl>
                <dt>{{ getTextdata('section2_text1') }}</dt>
                <dd>{{ getTextdata('section2_text2') }}</dd>
              </dl>
            </div>
          </div>
        </section>
        <!-- // On-Demand -->

        <!-- Start Watching the New LG Channels Home App! -->
        <section class="section03">
          <div class="section_inner">
            <div>
              <dl>
                <dt>{{ getTextdata('section3_text1') }}</dt>
                <dd>{{ getTextdata('section3_text2') }}</dd>
              </dl>
              <ul>
                <li><img src="../../assets/images/info/section03_img1@3x.png" alt="" /></li>
                <li><img src="../../assets/images/info/section03_img2@3x.png" alt="" /></li>
              </ul>
            </div>
          </div>
        </section>
        <!-- // Start Watching the New LG Channels Home App! -->
      </div>
      <!-- // contents -->

      <!-- footer -->
      <footer class="info_footer">
        <div class="title">{{ getTextdata('footer_text1') }}</div>
        <div class="info">{{ getTextdata('footer_text2') }}</div>
      </footer>
      <!-- // footer -->
    </div>
  </div>
</template>

<script>
import SI from '@/apis/service_interface';
export default {
  computed: {
    getTextdata() {
      return whattext => {
        return this.$t(`components.channelInfo.${whattext}`) ?? '';
      };
    },
  },
  mounted() {
    top.document.title = 'LG Channels Information | LG Channels WEB';
  },
  methods: {
    async toChannelList() {
      let isDevMode =
        location.hostname.split('.')[0].indexOf('dev') >= 0 || location.hostname.indexOf('lgchannels.com') < 0;
      let target_url = `${location.protocol}//${isDevMode ? 'dev-' : ''}channel-lineup.lgchannels.com`;

      const country = await SI.getCountry();
      const language = await SI.getLanguage();
      let query = `/?country=${country}&lang=${language}`;
      console.log('[channelInfo] go to channel-lineup', target_url + query);
      window.open(target_url + query);
    },
  },
};
</script>

<style lang="scss">
body {
  overflow: auto;
}
.pusblish-apply {
  color: white;
  background-color: black;
  font-family: 'LG SUXR', sans-serif !important;
}
.row {
  margin-right: 0px;
  margin-left: 0px;
}
.v-application .info {
  background-color: inherit !important;
}
.info_footer .title {
  font-size: 2.5rem !important;
  font-weight: normal !important;
  line-height: 3rem !important;
}
.info_header {
  word-break: keep-all;
}
.info_contents {
  word-break: keep-all;
}
.info_footer {
  word-break: keep-all;
}
.section03 {
  margin-bottom: 7rem;
}
@media screen and (max-width: 1240px) and (orientation: portrait) {
  .section03 {
    margin-bottom: 3.5rem;
  }
}
</style>
