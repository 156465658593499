<template>
  <div class="container_details continue-watching-container">
    <div class="category_related">
      <SuggestedVideo
        :category="continueWatchItems"
        @addToRecentlyWatched="addToRecentlyWatched"
        @addToFavoriteVOD="addToFavoriteVOD"
        @openHalfPlayer="openHalfPlayer"
        @closeHalfPlayer="closeHalfPlayer"
      />
      <row-card
        @addToRecentlyWatched="addToRecentlyWatched"
        @addToFavoriteVOD="addToFavoriteVOD"
        :category="favoriteItems"
        @openHalfPlayer="openHalfPlayer"
        @closeHalfPlayer="closeHalfPlayer"
      />
    </div>
  </div>
</template>
<script>
import RowCard from './rowCard.vue';
import SuggestedVideo from './suggestedVideo.vue';
export default {
  name: 'ContinueWatching',
  props: {
    continueWatchItems: Object,
    favoriteItems: Object,
  },
  components: {
    RowCard,
    SuggestedVideo,
  },
  methods: {
    openHalfPlayer(program) {
      this.$emit('openHalfPlayer', program);
    },
    closeHalfPlayer(programInfo, percentage, watchedTime) {
      this.$emit('closeHalfPlayer', programInfo, percentage, watchedTime);
    },
    addToRecentlyWatched(program) {
      this.$emit('addToRecentlyWatched', program);
    },
    addToFavoriteVOD(program) {
      this.$emit('addToFavoriteVOD', program);
    },
  },
};
</script>

<style>
.continue-watching-container .content_link_landscape {
  background-size: cover;
}
</style>
